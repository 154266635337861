var site = site || {};

(function ($, Drupal) {
  Drupal.behaviors.contentFormatterV1 = {
    arrowTimeout: false,
    attach: function (context) {
      var self = this;
      var $modules = $('.js-content-formatter--v1', context);
      var init = function () {
        $modules.each(function () {
          var $module = $(this);

          self.attachModule($module);
        });
      };

      $(window).off('resize.contentFormatter').on('resize.contentFormatter', _.debounce(function () {
        init();
      }, 100));
      init();
    },

    attachModule: function ($module) {
      var self = this;
      var $carousel = $('.js-content-formatter-carousel', $module);

      if (!$carousel.length) {
        return;
      }
      $carousel.removeClass('slick-not-needed');
      var breakpoint = Unison.fetch.now().name;
      var columnSetting = parseInt($module.data('columns-' + breakpoint) || 3);
      var $children = $('.js-carousel-slide', $carousel);
      var peekingAmount = !!$carousel.data('peeking') ? .2 : 0;

      if ($children.length > columnSetting) {
        if (!$carousel.hasClass('slick-initialized')) {
          $carousel.slick({
            slidesToShow: $module.data('columns-small') + peekingAmount,
            slidesToScroll: 1,
            mobileFirst: true,
            rtl: site.direction.isRTL,
            infinite: false,
            dots: false,
            responsive: [
              {
                breakpoint: Unison.fetch.all()['medium'] ? Unison.fetch.all()['medium'].replace(/[^0-9]/g, '') : 641,
                settings: {
                  centerMode: false,
                  dots: true,
                  slidesToShow: $module.data('columns-medium')
                }
              },
              {
                breakpoint: Unison.fetch.all()['large'] ? Unison.fetch.all()['large'].replace(/[^0-9]/g, '') : 1024,
                settings: {
                  centerMode: false,
                  dots: true,
                  slidesToShow: $module.data('columns-large')
                }
              }
            ]
          });
          $(document).trigger('contentFormatterSlickInitialized', $carousel);
        }
        $carousel.off('init afterChange').on('init afterChange', function () {
          self.alignArrows($carousel);
        });
        self.alignArrows($carousel);
      } else {
        if ($carousel.hasClass('slick-initialized')) {
          try {
            $carousel.slick('destroy');
          } catch (e) {
            console.error(e);
          }
        }
        $carousel.addClass('slick-not-needed');
      }
    },

    alignArrows: function ($carousel) {
      if (this.arrowTimeout) {
        clearTimeout(this.arrowTimeout);
      }
      this.arrowTimeout = setTimeout(function () {
        // Design wants arrows visually aligned with the image vs the entire slide
        var $arrowAlignmentContainer = $('.slick-active .js-content-block-carousel-arrow-alignment', $carousel);

        if ($arrowAlignmentContainer.length) {
          var $arrows = $('.slick-arrow', $carousel);

          $arrows.css({
            top: $arrowAlignmentContainer.height() / 2
          });
        }
      }, 100);
    }
  };
})(jQuery, Drupal);
